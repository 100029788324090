import { HeartOutlined } from "@ant-design/icons";
import WishlistTable from "./WishlistTable";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import EmptyWishlist from "./EmptyWishlist";

const Wishlist = () => {

  const {
    data: favItems,
  } = useGetFavouritesProductsQuery(undefined);
  const allFavs = favItems?.data ?? [];
  return (
    <div>
      {
        allFavs?.length === 0 ?
          <div className={`h-[70vh] flex justify-center`}>
            <EmptyWishlist />
          </div>
          :
          <>
            <div className="flex items-center flex-nowrap justify-center pt-11 pb-11 gap-[4px] bg-[#fafafa] text-[#0b3954]">
              <HeartOutlined className="text-[15px] block" />
              <h2 className="m-0 font-bold uppercase">Wishlist</h2>
            </div>
            <div
              className={`milgg:pr-[20%] pr-[20px] maxlm:pr-[25px] maxlm:pl-[25px] pl-[20px] 
							milgg:pl-[20%] pt-5 bg-[#ffffff] min-h-[50vh] hiilg:pb-[50px]`}
            >
              <WishlistTable favItems={allFavs} />
            </div>
          </>
      }
    </div>
  );
};

export default Wishlist;
