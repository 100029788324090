import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AgricultureLInks = ({ subCategoies }) => {  
  return (
    <div className={`p-4 grid grid-cols-3 gap-1`}>
      {subCategoies.map((cat : any) => (
        <div className={``}>
          <Link
            to={`shop/catogery/Details/${cat?.id}`}
            className={`text-[#0B3954] text-[17px] font-bold flex justify-start gap-1 mb-2 items-center`}
          >
            {cat?.name}
            <RightOutlined className={`text-[12px]`} />
          </Link>
          {/* {cat?.categories?.length > 0 &&
            cat?.categories?.map((category : any) => (
              <Link
                to={`shop/catogery/Details/${category?.id}`}
                className="text-[#828282] block mb-2"
              >
                <ArrowRightOutlined />
                <span className="pl-1">{category?.name}</span>
              </Link>
            ))} */}
        </div>
      ))}
    </div>
  );
};

export default AgricultureLInks;
