import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Button, Collapse, Radio } from "antd";
import { ArrowRightOutlined, LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PaymentMada from "../../../assets/svg/PaymentMada.svg";
import PaymentVisa from "../../../assets/svg/PaymentVisa.svg";
const Payment: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string | string[]>("1");

  const onChange = (key: string | string[]) => {
    console.log(key);
    setActiveKey(key);
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <p
            className={`flex items-center gap-4 text-[15px]  ${
              activeKey.length
                ? "text-[#222] duration-300"
                : "text-[#888] duration-300"
            }  p-5`}
          >
            <span
              className={`text-[20px] w-[37px] h-[37px] border-[1px] text-center font-bold border-[#ccc] rounded-[50%] ${
                activeKey.length
                  ? "text-[#fff] bg-[#222]  duration-300"
                  : "text-[#888] duration-300"
              }`}
            >
              2
            </span>
            <span className="font-bold text-[20px]">Payment</span>
          </p>
        </>
      ),
      children: (
        <>
          <div className="flex flex-col w-full gap-4">
            <Radio>
              <div className="flex items-center gap-3">
                <span> mada debit card </span>
                <img src={PaymentMada} alt="" />
              </div>
            </Radio>
            <Radio>
              <div className="flex items-center gap-3">
                <span> Hyperpay Gateway </span>
                <img src={PaymentVisa} alt="" />
              </div>
            </Radio>
          </div>
          <div className="mt-[30px] mb-[20px]">
            <p className="text-[#54595fc5]">
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our
            </p>
            <Link to="" className="font-bold">
              privacy policy.
            </Link>
          </div>
          <div className="flex items-center justify-between xxllg:flex-col xxllg:gap-2 w-full">
            <Link to="/" className="flex items-center">
              <LeftOutlined className="text-[12px]" />
              <p className="pl-1">Return to billing details</p>
            </Link>
            <Button disabled className="w-fit bg-[#009e97] text-[#fff] pr-[30px] pl-[30px] pt-[8px] pb-[8px] rounded-[5px] cursor-pointer duration-300">
              <span className="pr-2 font-bold">Place Order</span>
              <ArrowRightOutlined className="text-[13px]" />
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <Collapse
      items={items}
      expandIcon={() => null}
      defaultActiveKey={["1"]}
      onChange={onChange}
      className="mt-5 Login"
    />
  );
};

export default Payment;
