import { DeleteFilled } from "@ant-design/icons";
import { message, Table } from "antd";
import { useAddToCartMutation } from "../../redux/Service/api";


const CartTable = ({ allCartData }) => {

  const [ addToCart ] = useAddToCartMutation();
  const CardId = allCartData?.data?.data?.map((ele)=>{
    return ele?.id;
  })
  const handleAddToCart = async (id : number) => {
    try {
      const res = await addToCart({
        item_id: id,
        quantity: 1,
      });
      if(res?.data?.status){        
        CardId?.find((el) => el === id) ? message.error("Item removed from cart") : message.success("Item added to cart");
      }
      
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const data = allCartData?.map((item: any) => {
    return {
      key: item?.id,
      title: item?.name,
      price: item?.price,
      ProductImage: item?.photo,
      quantity: item?.quantity,
      subTotal: item?.price * (item?.quantity || 1),
    };
  });

  const columns: any = [
    {
      title: "Product",
      dataIndex: "ProductImage",
      render: (img : any, record : any) => 
      <div className={` relative group flex flex-col items-center justify-center overflow-hidden`}>
         <img className="w-[100px]" src={img} alt="ProductImage" />
         <span 
          onClick={() => handleAddToCart(Number(record?.key))}
         className={`absolute top-0 left-[-50%] cursor-pointer group-hover:left-0 block text-center bg-[#0b3954] rounded-[5px] hover:bg-[#217873] duration-300 ease-linear text-[#fff] pt-3 font-bold pb-3`}>
            <DeleteFilled className={`text-[20px] pt-[1px] pb-[1px] pr-2 pl-2`} />
         </span>
      </div>,
    },
    {
      title: "Name",
      dataIndex: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "SubTotal",
      dataIndex: "subTotal",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
      className="miillg:mt-[20px]"
    />
  );
};

export default CartTable;
