import ElementorImage from './ElementorImage'
import ElementorSlider from './ElementorSlider'
import './elementorContainer.scss'
const ElementorContainer = () => {
	return (
<>
<div 
		className={`mt-6 flex flex-nowrap maxlm:gap-0 gap-5 miilg:gap-0 miilg:flex-wrap`}
		>
			<ElementorSlider/>
			<ElementorImage/>
		</div>
</>
	)
}

export default ElementorContainer