import { Link } from "react-router-dom";
const HelpCard = ({ category }) => {
  return (
    <>
      <Link
        to={`/shop/catogery/Details/${category.id}`}
        className="overflow-x-hidden group mainhelp h-[23vh] xxllg:h-[26vh] rounded-[10px]"
      >
        <img src={category.photo} className={`h-full w-full`} alt="" />
      </Link>
    </>
  );
};

export default HelpCard;
