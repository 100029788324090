import { useEffect } from "react";
import { Outlet, useLocation } from "react-router";
import Footer from "./Footer";
import Header from "./Header";

const PaymentLayout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      {/* <PaymentHeader /> */}
      <div className="pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] miilg:pl-[20px] bg-[#f2fbfc]">
        <Header />
      </div>
      <div className={`milgg:pr-[20%] pr-[50px] maxlm:pr-[25px] maxlm:pl-[25px] pl-[50px] 
				milgg:pl-[20%] pt-5 bg-[#f1f1f1] min-h-[50vh] hiilg:pb-[50px]`}>
        <Outlet />
				
      </div>
			<div className="milgg:pr-[20%] pr-[50px] pl-[50px] milgg:pl-[20%] pt-5 bg-[#edf4f4]">

			<Footer/>
			</div>

    </>
  );
};

export default PaymentLayout;
