import React from "react";
import { Table, message } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
} from "../../redux/Service/api";

const WishlistTable = ({ favItems }) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((st: any) => st.auth);
  const [
    addToCart,
    {
      data: addData,
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddToCartMutation();
const CardId = addData?.data?.map((ele)=>{
  return ele?.id
})

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();

  const handleAddToCart = async (itemId : Number) => {
    if (!isAuthenticated) {
      message.info("You must be logged in");
    }      
    try {
      const res = await addToCart({
        item_id: itemId,
        quantity: 1,
      });
      if(res?.data?.status){        
        CardId?.find((el : number) => el === itemId) ? message.error("Item removed from cart") : message.success("Item added to cart");
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const columns: any = [
    {
      title: "Product image",
      dataIndex: "ProductImage",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      // className: "column-money",
      dataIndex: "price",
      align: "left",
    },

    {
      title: "Action",
      dataIndex: "Action",
    },
  ];

  const data: any = favItems?.map((item) => {
    return {
      key: `${item.id}`,
      ProductImage: (
        <div className="flex gap-[14px] maxlm:gap-[5px] miillg:gap-[60px] miillg:items-center w-full flex-nowrap text-[black]">
          <div className="max-w-[50%] miillg:max-w-[30%] miillg:h-[150px] maxlm:h-[100px]  h-[100px] bg-[#f5f5f5] rounded-[5px]">
            <img className="w-full h-full" src={item.photo} alt="" />
          </div>
          <div className="min-w-[100px]">
            <p className="text-[17px] font-bold miillg:text-[14px]">
              {item.name}
            </p>
            <p className="">{item.short_description}</p>
          </div>
        </div>
      ),
      price: (
        <div className="flex items-center justify-between flex-nowrap">
          <p className="font-bold niillg:hidden">Price :</p>
          <p>
            <span>{item.price}</span>
            <span> SAR</span>
          </p>
        </div>
      ),
      Action: (
        <>
          <div className="flex items-center justify-between gap-[10px] flex-nowrap">
            <EyeOutlined
              onClick={() => navigate(`/shop/catogery/products/${item?.id}`)}
              className="text-[#fff] bg-[#0b3954] hover:bg-[#009e97] p-3 rounded-[5px] cursor-pointer	duration-300"
            />
            <p
              onClick={() => handleAddToCart(item?.id)}
              className="font-bold text-[#fff] bg-[#009e97] hover:bg-[#0b3954] duration-300 cursor-pointer w-[100%] text-center pt-2 pb-2 rounded-[5px]"
            >
              Add To Cart
            </p>
            <DeleteOutlined
              onClick={() => {
                addOrDeleteProduct({ item_id: item.id });
              }}
              className="text-[#fff] bg-[#0b3954] p-3 rounded-[5px] cursor-pointer duration-300"
            />
          </div>
        </>
      ),

      SKU: "New York No. 1 Lake Park",
      Stockstatus: <>Out of stock</>,
    };
  });

  return (
    <Table
      loading={addIsLoading || addOrDelIsLoading}
      columns={columns}
      dataSource={data}
      bordered
      // title={() => 'Header'}
      // footer={Footer}
      pagination={false}
      className="miillg:mt-[20px]"
    />
  );
};

export default WishlistTable;
