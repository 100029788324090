import FooterPaymentImage from '../../assets/svg/FooterPaymentImage.svg'
const FooterButtom = () => {
	return (
		<div className='pt-8 pb-8 text-[#828282] flex justify-between flex-nowrap items-center miillg:flex-col miillg:gap-[20px]'>
			<span className='min-w-[50%] maxlm:text-[13px]'>
			Copyright © 2023 Cavalo online store, All rights reserved.
			</span>
			<img src={FooterPaymentImage} alt="" />
		</div>
	)
}

export default FooterButtom