import React from 'react';
import { Form, Input, Button, Space, message } from 'antd';
// import { send } from 'emailjs-com';

const ContactUsForm: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    console.log("Form submitted:", values);
    if(values.email && values.message && values.name && values.subject){
      message.success('Email sent successfully');
      form.resetFields();
    }
    // EmailJS service configuration
    // const serviceID = "YOUR_SERVICE_ID";        // Replace with your EmailJS service ID
    // const templateID = "YOUR_TEMPLATE_ID";      // Replace with your EmailJS template ID
    // const userID = "YOUR_PUBLIC_USER_ID";       // Replace with your EmailJS public user ID

    // // Send email through EmailJS
    // try {
    //   await send(serviceID, templateID, values, userID);
    //   console.log("Email sent successfully!");
    //   form.resetFields(); // Clear the form after successful submission
    // } catch (error) {
    //   console.error("Failed to send email:", error);
    // }
  };

  return (
    <div className="contactForm">
      <Form
        form={form}
        onFinish={onFinish}
        name="contact_form"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex xllg:flex-col w-full justify-between customitem">
          <Form.Item
            name="name"
            label="Name *"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input className="h-[50px]" placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email *"
            rules={[
              { required: true, message: "Please enter your email" },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input className="h-[50px]" placeholder="Enter e-mail" />
          </Form.Item>
        </div>
        <Form.Item
          name="subject"
          label="Subject *"
          rules={[{ required: true, message: "Please enter a subject" }]}
        >
          <Input className="h-[50px]" placeholder="Enter Subject" />
        </Form.Item>
        <Form.Item
          name="message"
          label="Message *"
          rules={[{ required: true, message: "Please enter a message" }]}
        >
          <Input.TextArea
            className="!resize-none"
            rows={4}
            placeholder="Write message"
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactUsForm;
