import React, { useState } from "react";
import type { DrawerProps } from "antd";
import { Drawer, Space } from "antd";
import {
  CloseOutlined,
  DatabaseFilled,
  HeartOutlined,
  HomeFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BannerDrawer = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");
  const { isAuthenticated } = useSelector((state: any) => state.auth);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Space>
        <div
          onClick={showDrawer}
          className="miillg:flex miillg:flex-col miillg:items-center niillg:hidden"
        >
          <DatabaseFilled className="text-[20px] miillg:text-[30px] text-[#0b3954]" />
          <span className="miillg:text-[13px] niillg:hidden">More</span>
        </div>
      </Space>
      <Drawer
        title={
          <div>
            <CloseOutlined
              onClick={() => setOpen(false)}
              className="text-[25px]"
            />
          </div>
        }
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        className="Draower_more"
      >
        <div className='flex flex-col items-center gap-4 text-[#0b3954]'>
				<Link
          to={""}
          className=""
        >
          <HomeFilled className="text-[25px]  text-[#0b3954]" onClick={()=>setOpen(false)}/>
        </Link>
				<Link to={"/shop"} className="text-[#0b3954] text-[18px]" onClick={()=>setOpen(false)}>
          Shop
        </Link>
				<Link to={""} className="text-[#0b3954] text-[18px]" onClick={()=>setOpen(false)}>
          About us
        </Link>
        <Link to={""} className="text-[#0b3954] text-[18px]" onClick={()=>setOpen(false)}>
          News
        </Link>
        <Link to={""} className="text-[#0b3954] text-[18px]" onClick={()=>setOpen(false)}>
          Cavalo.com
        </Link>
        <Link to={""} className="text-[#0b3954] text-[18px]" onClick={()=>setOpen(false)}>
          Quotes List
        </Link>
        <Link
          to={"/wishlist"}
          className="flex items-center gap-1 text-[#0b3954] text-[18px]"
        >
          {/* icon */}
          <HeartOutlined />
          <span>Wishlist</span>
        </Link>
        <Link to={""} className="text-[#0b3954] text-[18px]" onClick={()=>setOpen(false)}>
          {/* icon */}
          <span>Compare</span>
        </Link>
				</div>
      </Drawer>
    </>
  );
};

export default BannerDrawer;
