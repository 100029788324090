import img from "../../assets/image/CAVALO-PIC.png";
import AboutCollapse from "./AboutCollapse";
const AboutTrustSection = () => {
  return (
    <div className="text-[30px] text-[#009E97] bg-[#fff] mt-11">
      <h2 className="mb-11 maxlm:text-[18px]">
        Here's why you can trust Cavalo Store:
      </h2>

      <div className="flex flex-col gap-[20px]">
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>1.</span>
          <span className="leading-[25px]">
            Quality Products: We are committed to offering only the highest
            quality equestrian products. We carefully curate our inventory,
            selecting trusted brands and manufacturers known for their
            craftsmanship and attention to detail. Rest assured that every item
            you purchase from Cavalo Store has undergone rigorous quality checks
            to meet our stringent standards.
          </span>
        </p>
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>1.</span>
          <span className="leading-[25px]">
            Quality Products: We are committed to offering only the highest
            quality equestrian products. We carefully curate our inventory,
            selecting trusted brands and manufacturers known for their
            craftsmanship and attention to detail. Rest assured that every item
            you purchase from Cavalo Store has undergone rigorous quality checks
            to meet our stringent standards.
          </span>
        </p>
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>1.</span>
          <span className="leading-[25px]">
            Quality Products: We are committed to offering only the highest
            quality equestrian products. We carefully curate our inventory,
            selecting trusted brands and manufacturers known for their
            craftsmanship and attention to detail. Rest assured that every item
            you purchase from Cavalo Store has undergone rigorous quality checks
            to meet our stringent standards.
          </span>
        </p>
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>1.</span>
          <span className=" leading-[25px]">
            Quality Products: We are committed to offering only the highest
            quality equestrian products. We carefully curate our inventory,
            selecting trusted brands and manufacturers known for their
            craftsmanship and attention to detail. Rest assured that every item
            you purchase from Cavalo Store has undergone rigorous quality checks
            to meet our stringent standards.
          </span>
        </p>
      </div>
      <div className="flex gap-[100px] miillg:flex-col  hiilg:gap-[40px] hiilg:justify-between mt-11 ">
        <div className="w-[35%] miillg:w-[100%] miillg:max-h-[unset] miillg:h-[650px] maxlm:h-[400px] hiilg:w-[45%] about_image max-h-[540px]">
          <img src={img} className="w-full h-full" alt="" />
        </div>
        <div className="w-[50%] miillg:w-[100%] aboutCollapse">
          <AboutCollapse />
        </div>
      </div>
    </div>
  );
};

export default AboutTrustSection;
