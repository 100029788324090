import { Link } from "react-router-dom";
import '../home.scss';

const AgricultureImage = ({ category }) => {
  
  return (
    <Link
      to={`shop/catogery/${category.id}`}
      className={`ahmed hilg:w-full 
      
        border-[1px] solid border-[#ccc] h-[70vh] w-[49%] min-w-[35%] 
		hilg:min-w-[35%] bg-[#cccccc3c] relative overflow-hidden`}
    >
      <div className="group h-full">
        {/* <div className="absolute top-0 left-0 bg-[#000000] opacity-[0.3] h-full w-full z-40"></div>
        <div className="absolute top-[50%] translate-y-[-50%] left-[30px] z-40">
          <h2 className="text-[#0C8884] text-[30px] font-bold mb-4">
            {category.name}
          </h2>
          <p className="mb-4 w-[80%] text-[#ffffff] text-[17px]">
            We offer a comprehensive range of services for all the necessary
            components for successful poultry operations
          </p>
          <p className="text-[17px] font-bold">Discover Now</p>
        </div> */}
        <img
          src={category.photo}
          className="w-full h-full  group-hover:scale-[1.2] duration-[0.4s]"
          alt=""
        />
      </div>
    </Link>
  );
};

export default AgricultureImage;
