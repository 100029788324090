import TreadMarkSlider from "../../components/common/TreadMarkslider/TreadMarkSlider";
import ElementorContainer from "./ElementorContainer/ElementorContainer";
import ServicesSectionBox from "./ServicesSection/ServicesSectionBox";
import { ReactComponent as FreeDelivery } from "../../assets/svg/FreeDelivery.svg";
import { ReactComponent as Payment } from "../../assets/svg/Payment.svg";
import { ReactComponent as Support } from "../../assets/svg/Support.svg";
import HelpSection from "./HelpSection/HelpSection";
import HelpCatogery from "./HelpCatogery/HelpCatogery";
import PopulatedSection from "./populatedSection/PopulatedSection";
import AgricultureSection from "./AgricultureSection/AgricultureSection";
import './home.scss';

const Home = () => {
  return (
    <div className="pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] miilg:pl-[20px] bg-[#ffff]">
      <div className={`w-full h-full cursor-pointer`}>
        <TreadMarkSlider Duration={2000} />
        <ElementorContainer />
        <div className={`flex justify-between mt-11 mb-11 flex-wrap gap-y-10`}>
          <ServicesSectionBox
            title={`Services`}
            icon={<FreeDelivery />}
            describtion={`Over 1000 SAR in Riyadh`}
          />
          <ServicesSectionBox
            title={`Online payment`}
            icon={<Payment />}
            describtion={`All credit cards & Paypal acceted`}
          />
          <ServicesSectionBox
            title={`Backorder support`}
            icon={<Support />}
            describtion={`Wide selection of high-quality brands`}
          />
        </div>
        <HelpSection />
        <HelpCatogery />
        <PopulatedSection />
        <AgricultureSection />
      </div>
    </div>
  );
};

export default Home;
