import SkeletonImage from '../../../components/SkeletonImage/SkeletonImage';
import Img from '../../../assets/swiper/slider2.png'
const ElementorImage = () => {
	return (
		<div className='min-w-[30%] miilg:w-full relative h-[475px] maxlm:h-[410px] xxllg:h-[400px]'>
			<SkeletonImage/>
			<img src={Img} className={`w-full h-full`} alt="" />
		</div>
	)
}

export default ElementorImage