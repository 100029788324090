import { useGetAllCategoriesQuery, useGetBannersQuery } from "../../../redux/Service/api";
import HelpCard from "./HelpCard";
import "./HelpCatogery.scss";
const HelpCatogery = () => {
  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
  } = useGetBannersQuery(undefined);

  const allCategories = allCategoriessData?.data ?? [];
console.log("allCategories", allCategories);

  const lowerCategories = getLowerCategories(allCategories);

  function getLowerCategories(categories) {
    const lowCat = [];

    categories?.forEach((c) => {
      if (c.items_count > 0) {
        lowCat.push(c); // Add the category if item count > 0
      }

      // If it has nested categories, explore them
      if (c.categories?.length) {
        lowCat.push(...getLowerCategories(c.categories));
      }
    });

    return lowCat;
  }

  console.log("allCategories", allCategories);
  console.log("getLowerCategories", lowerCategories);

  return (
    <div className="grid grid-cols-4 hiilg:grid-cols-3 xllg:grid-cols-2 xxllg:grid-cols-1 gap-[15px] pt-10 pb-10 HelpCatogery mb-11">
      {allCategories?.map((category : any) => {
        return <HelpCard category={category} />;
      })}
    </div>
  );
};

export default HelpCatogery;
