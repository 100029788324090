import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import {
  useGetAllCategoriesQuery,
  useGetCategoryDetailsQuery,
} from "../../redux/Service/api";
import { useNavigate, useParams } from "react-router";

import SkeletonCard from "../../components/SkeletonCard";
const Shop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: allCategoriesData,
    isLoading: isAllCategoriesLoading,
  } = useGetAllCategoriesQuery(undefined);

  const {
    data: categoryDetailsData,
    isLoading: isCategoryDetailsLoading,
  } = useGetCategoryDetailsQuery(id, { skip: !id }); // Skip if no id

  // Use category details if id exists, otherwise all categories
  const categories = id
    ? categoryDetailsData?.data?.categories || []
    : allCategoriesData?.data || [];

  const handleCategoryClick = (category) => {
    if (category.categories.length > 0 && !category.items_count) {
      navigate(`/shop/catogery/${category.id}`);
    } else if (category.items_count) {
      navigate(`/shop/catogery/Details/${category.id}`);
    }
  };

  return (
    <div className="flex flex-col relative">
      <ShopLayoutTitle title={"Home"} PathTo={"/"} Page={"Shop"} />
      <div className="min-h-[50vh] flex flex-wrap justify-start maxlm:justify-center gap-x-[25px] gap-y-[25px] items-start w-full relative">
        {(isCategoryDetailsLoading || isAllCategoriesLoading) && (
          <div className={`w-full grid grid-cols-4 maxlg:grid-cols-3 xllg:grid-cols-2 xxllg:grid-cols-1 gap-4`}>
            {
              Array(8).fill(null).map((_, index) => (
                <SkeletonCard key={index} />
              ))
            }
          </div>
        )}
        <div className="relative grid grid-cols-4 maxlg:grid-cols-3 xllg:grid-cols-2 xxllg:grid-cols-1 gap-4 overflow-x-hidden duration-300 group cursor-pointer" >
          {categories?.map((category: any) => (
            <span
              onClick={() => handleCategoryClick(category)}
              className="pb-[10px] min-w-[21.2%] border-[1px] solid rounded-[8px] shadow-sm"
            >
              <div className="h-[270px] bg-[#cccccc79] rounded-[50%]">
                <img src={category?.photo} className="h-full border-b-[1px] solid rounded-tr-[8px] rounded-tl-[8px]" alt="" />
              </div>
              <div className="flex flex-col items-center mt-4">
                <p className="font-bold uppercase">{category?.name}</p>
                <p className="text-[#888] font-bold text-[13px]">
                  <span className="pr-1">
                    {category?.items_count
                      ? category?.items_count
                      : category?.categories?.length}
                  </span>
                  <span>products</span>
                </p>
              </div>
            </span>
          ))}
        </div>

      </div>
    </div>
  );
};

export default Shop;
