import { EyeOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import { useGetOneProductModalMutation, useGetOneProductQuery } from "../../redux/Service/api";
import DeatailsContant from "../../pages/ShopCatogeryDetails/DeatailsContant";
import './common.scss';
type IProps = {
	productId: number;
}
const EyeModeletails = ({ productId }: IProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);


	// const {
	// 	data: productDetails,
	// 	isError: isProductDetailsError,
	// 	isLoading: isProductDetailsLoading,
	// } = useGetOneProductQuery(productId);

	const [getOneProductModal , { isLoading : isProductDetailsLoading, isError, data : productDetails }] = useGetOneProductModalMutation();

	const showModal = async() => {
		const res = await getOneProductModal(productId);
		if(res?.data?.status){
			setIsModalOpen(true);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	

	const product = productDetails?.data ? productDetails?.data   : {};
	console.log("product", product);
	
	return (

		<>
			<EyeOutlined
				className={`${isProductDetailsLoading ? "eyeRotate" : "" }  text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer`}
				onClick={showModal}
			/>
			<Modal 
				title=""
				footer={null}
				open={isModalOpen}  
				onCancel={handleCancel}
				centered={true}
				
			>
				<div className="flex flex-col w-full ShopDetails miillg:mt-10">
					<div className="flex gap-[30px] miillg:flex-col">
						<div className="w-[30%] miillg:w-full ">
							<div className="border-[1px] solid border-[#ccc] mb-4 miillg:flex miillg:justify-center">
								<img
									src={product?.photo}
									alt=""
									className="w-full miillg:w-[50%] h-full max-h-[80vh] object-cover"
								/>
							</div>
						</div>
						<DeatailsContant product={product} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default EyeModeletails