import React from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "Convenient Shopping Experience:",
    children: (
      <p>
        With Cavalo’s online store, you can browse and shop for all your
        equestrian needs from the comfort of your home, stable, or anywhere you
        have an internet connection. No more driving from store to store or
        dealing with crowded parking lots. Our website is user-friendly,
        allowing you to easily navigate through different categories, view
        product details, and make purchases with just a few clicks.
      </p>
    ),
  },
  {
    key: "2",
    label: "Detailed Product Information:",
    children: <p>
			We understand that purchasing equestrian equipment requires careful consideration. That’s why we provide detailed product descriptions, specifications, and high-resolution images to help you make informed decisions. You can learn about the features, materials, and sizing options before making a purchase, ensuring that you select the right product that suits your needs.
		</p>,
  },
  {
    key: "3",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "4",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "5",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "6",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "6",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "6",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "6",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "6",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
  {
    key: "6",
    label: "Competitive Pricing and Special Offers:",
    children: <p>Cavalo Online Store offers competitive pricing on all our products. We strive to provide great value for your money without compromising on quality. Additionally, we frequently run special promotions, discounts, and clearance sales, allowing you to save even more on your favorite equestrian items. Be sure to check our website regularly for exclusive deals and offers.</p>,
  },
];

const AboutCollapse: React.FC = () => <Collapse accordion items={items} />;

export default AboutCollapse;
