import { useGetCartProductsQuery } from "../../redux/Service/api";
import CheckoutCart from "./CheckoutCart";

const CheckoutOrder = () => {

    const {
      data: cartData,
    } = useGetCartProductsQuery(undefined);
  return (
    <div className={`bg-[#fff] sticky top-0  w-full p-5 h-fit`}>
      <p className="text-[#222] font-bold border-b-[1px] border-[#ccc] pb-[10px]">
        Your order
      </p>
      {
        cartData?.data?.data?.map((ele)=>{
          return (<CheckoutCart key={ele?.id}
          id = {ele?.id}
          title = {ele?.name}
          image = {ele?.photo}
          price = {ele?.price}
          total = {ele?.total}   
          quantity = {ele?.quantity}       
          />
        )})
      }
      <p className="border-b-[1px] border-[#ccc] pb-[10px] mb-4 pt-[10px] flex justify-between flex-nowarp">
        <span className="text-[20px] uppercase font-bold">Total</span>
        <span className="text-[18px]">{cartData?.data?.total_price} SAR</span>
      </p>
    </div>
  );
};

export default CheckoutOrder;
