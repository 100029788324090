import { useNavigate } from "react-router-dom";
import {
  LoadingOutlined,
  ShoppingFilled,
  ShoppingOutlined,
} from "@ant-design/icons";
import { useAddToCartMutation, useGetCartProductsQuery } from "../../redux/Service/api";
import { useSelector } from "react-redux";
import { Button, message } from "antd";
import { useState } from "react";
const DeatailsContant = ({ product }) => {
  const [productCount, setProductCount] = useState(1);

  const { isAuthenticated } = useSelector((st: any) => st.auth);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();


  const [ addToCart, { isLoading: addIsLoading } ] = useAddToCartMutation();
  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    try {
      const res: any = await addToCart({
        item_id: product?.id,
        quantity: productCount,
      });
      if(res.data.status){
        CardId?.find((el) => el === product?.id) ? message.error("Item removed from cart") : message.success("Item added to cart"); 
        
      }
      

      setProductCount(1);
    } catch (error: any) {
      console.error("Error adding item to cart:", error);
    }
  };


    const { data: cartData } = useGetCartProductsQuery(undefined);
  const CardId = cartData?.data?.data?.map((ele)=>{
    return ele?.id;
  })

  return (
    <div className="flex flex-col gap-5 w-[70%] miillg:w-full">
      {contextHolder}
      <div className="min-h-[50%]">
        <h2 className="text-[#222] text-[18px] font-bold">{product?.name}</h2>
        <p className="text-[#00968F]">{product?.price} SAR</p>
        <p>{product?.short_description}</p>
      </div>
      <div>
        {
          CardId?.find((el) => el === product?.id) ? 
          <Button
          disabled={addIsLoading}
          className="w-full uppercase bg-[#009e97] text-[#fff] pt-2 pb-2 flex justify-center items-center flex-nowrap gap-3 cursor-pointer hover:!bg-[#009e97] hover:!text-[#fff] border-none"
          onClick={handleAddToCart}
        >
          {
            addIsLoading ? 
            <LoadingOutlined className={`text-[20px] text-[#ffffff]`} />
            :
          <ShoppingFilled className={`text-[20px] text-[#ffffff]`}/>
          }
          <span>Already in the cart</span>
        </Button>
          :
          <Button
          disabled={addIsLoading}
          className="w-full uppercase bg-[#009e97] text-[#fff] pt-2 pb-2 flex justify-center items-center flex-nowrap gap-3 cursor-pointer hover:!bg-[#009e97] hover:!text-[#fff] border-none"
          onClick={handleAddToCart}
        >
          {
            addIsLoading ? 
            <LoadingOutlined className={`text-[20px] text-[#ffffff]`} />
            :
          <ShoppingOutlined className={`text-[20px] text-[#ffffff]`}/>
          }
          
          <span>Add to Cart</span>
        </Button>
        }

        <div>
        </div>
        <div className="flex gap-5 mt-4 flex-nowrap xxllg:flex-col">
          <p>
            <span>Category : </span>
            <span className="text-[#888888]">{product?.category?.name}</span>
          </p>
          <p>
            <span>Brand : </span>
            <span className="text-[#888888]">{product?.brand?.name}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default DeatailsContant;
