import { useMediaQuery } from "react-responsive";

import ContentLoader from "react-content-loader";

const SkeletonImage = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1600px)' });

  return (
    <div className="absolute top-0 left-0 w-full overflow-hidden"> 
      <ContentLoader
        speed={2}
        width={isBigScreen ? 1000 : isMobile ? 800 : 400}
        height={460}
        // viewBox={`${isMobile ? "0 0 800 460" : "0 0 400 460"}`}
        viewBox={`${isBigScreen ? "1000" : isMobile ? "800" : "400"}`}
        backgroundColor="rgba(255, 255, 255, 0)" 
        foregroundColor="rgba(255, 255, 255, 0.5)"
        {...props}
      >
        <rect x="0" y="0" rx="2" ry="2" width={`${isBigScreen ? "1000" : isMobile ? "800" : "400"}`} height="460" /> 
      </ContentLoader>
    </div>
  );
};

export default SkeletonImage;
