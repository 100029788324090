import {
  EyeOutlined,
  ShoppingOutlined,
  HeartOutlined,
  HeartFilled,
  ShoppingFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
  useGetFavouritesProductsQuery,
} from "../../../redux/Service/api";
import { Spin, message } from "antd";
import { useSelector } from "react-redux";
import UserDrawer from "../../UserDrawer";
import { useState } from "react";
import EyeModeletails from "../EyeModeletails";

type IProps = {
  CardId?: number[],
  product?: any,
}
const MenuRight = ({ product, CardId }: IProps) => {
  const { isAuthenticated } = useSelector((st: any) => st.auth);
  const [customOpen, setCustomOpen] = useState<boolean>(false);

  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery(undefined);

  const favItems = favItemsData?.data ?? null;

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();

  const [
    addToCart,
    {
      data: addData,
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddToCartMutation();


  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      message.info("You must be logged in");
      setCustomOpen(true);
    }
    try {
      const res = await addToCart({
        item_id: product?.id,
        quantity: 1,
      });
      
      if(res?.data?.status){        
        CardId?.find((el) => el === product?.id) ? message.error("Item removed from cart") : message.success("Item added to cart");
      }
      
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleAddOrRemoveToFavourit = async() => {
    if (product && product.id) {
      const res = await addOrDeleteProduct({ item_id: product.id });
      if(res?.data?.status){
        favItems?.find((el) => el?.id === product?.id) ? message.error("Item removed from Favourite") : message.success("Item added to Favourite");
      }
    }
  };

  return (
    <div className="absolute pt-2 top-[50%] translate-y-[-65%] right-[-50px] z-50 flex flex-col items-center justify-center bg-black group-hover:right-2 transition-all duration-400 linear ">
      {
        !isAuthenticated &&  <UserDrawer customOpen={customOpen} /> 
      }
      {addOrDelIsLoading && (
        <div className="absolute top-0 right-0 left-0 bottom-0 bg-[#000000b5] flex items-center justify-center z-10">
          <Spin className="" />
        </div>
      )}


      {CardId?.find((el) => el === product?.id) ? (
        addIsLoading ? 
        <Spin className="text-[#ffff] text-[18px] p-3" />
        :
        <ShoppingFilled
          title="this booked"
          className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer"
          onClick={handleAddToCart}
        />
      ) : (
        addIsLoading ? 
        <Spin className="text-[#ffff] text-[18px] p-3 " />
        :
        <ShoppingOutlined
          className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer"
          onClick={handleAddToCart}
        />
      )}

      {favItems?.find((el) => el.id === product?.id) ? (
        <HeartFilled
          className="text-red-500 text-[18px] cursor-pointer p-3 hover:bg-[#cccccc2b]"
          title="remove from favourite"
          onClick={handleAddOrRemoveToFavourit}
        />
      ) : (
        <HeartOutlined
          title="add to favourite"
          className="text-white text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer"
          onClick={handleAddOrRemoveToFavourit}
        />
      )}



      <EyeModeletails productId={product?.id}/>

  
    </div>
  );
};

export default MenuRight;
