import React, { useEffect } from "react";
import { Button, Form, Input, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useGetLogedUserQuery, useUpdateUserDataMutation } from "../../redux/Service/api";
import { saveUserData } from "../../redux/Slices/authSlice";

const AcountForm: React.FC = () => {
  const [form] = Form.useForm();
  const { userData } = useSelector((state: any) => state.auth);
const dispatch = useDispatch();

const formdata = new FormData();
formdata.append('email',userData?.email);
formdata.append('name',userData?.name);

  const {
    data : getUserData,
    isError: getUserError,
  } = useGetLogedUserQuery(formdata);

  const [UpdateUserData, {data : UserUpdated , isLoading : UserUpdatedLoading}] = useUpdateUserDataMutation({});
  console.log("UserUpdated?.data", UserUpdated);
  
  const UserPhone = window.localStorage.getItem('phone');
  useEffect(() => {
    if (getUserData?.data) {
      form.setFieldsValue(getUserData?.data);
    }
    if(UserUpdated?.data){
      form.setFieldsValue(UserUpdated?.data);
    }
  }, [getUserData?.data, form, UserUpdated?.data]);

  const onFinish = async (values: any) => { 
    if(values?.name && values?.address && values?.phone){
      const formdata = new FormData();
      formdata.append('email', values?.email);
      formdata.append('address', values?.address);
      formdata.append('_method', "PUT");
      formdata.append('phone', values?.phone);

      const res = await UpdateUserData(formdata);
      console.log("res?.data", res?.data?.data);
      
      if(res?.data?.message === "message.update"){
        dispatch(saveUserData(res.data?.data));
        // window.localStorage.setItem('phone', values.phone);
      }
    }
  };

  return (
    <div className="w-[68%] miillg:w-[100%] acountForm">
      <Form
        form={form}
        name="accountForm"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item name="email" label="Email Address">
          <Input className="h-[50px]" placeholder="Enter e-mail" disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: false, message: "Please enter your name" }]}
        >
          <Input className="h-[50px]" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
            {
              pattern: /^[0-9]+$/,
              message: "Phone number can only contain digits",
            },
          
          {
            min: 8,
            message: "Phone number must be at least 8 digits long",
          },
          ]}
        >
          <Input className="h-[50px]" placeholder="Enter phone number" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Please enter your address" }]}
        >
          <Input className="h-[50px]" placeholder="Enter address" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" disabled={UserUpdatedLoading}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AcountForm;
