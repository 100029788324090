import AgricultureLInks from "./AgricultureLInks";
import AgricultureImage from "./AgricultureImage";
import { useGetAllCategoriesQuery } from "../../../redux/Service/api";

const AgricultureSection = () => {
  const {
    data: allCategoriessData,
  } = useGetAllCategoriesQuery(undefined);

  const allCategories = allCategoriessData?.data ?? [];

  return (
    <div className={`flex flex-col gap-4 pb-10 mainCat`}>
      {allCategories?.map((category : any) => (
        <div className={`catBox flex flex-nowrap 
        hilg:flex-col justify-between border-[2px] 
        solid #ccc rounded-[10px] hover:border-[#0b3954]
         duration-[0.3s]
        shadow-sm`}>
          <AgricultureImage category={category} />
          <div className={`w-[49%] hilg:w-full hilg:pl-2 hilg:pr-2`}>
            {category.categories && category.categories.length > 0 && (
              <AgricultureLInks subCategoies={category?.categories?.slice(0, 36)} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AgricultureSection;
