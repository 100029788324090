import { Outlet } from "react-router";
import ShopAside from "./ShopAside/ShopAside";

const ShopLayout = () => {
  return (
    <div className={`pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] 
    miilg:pr-[20px] miilg:pl-[20px]  bg-[#fffff] mb-11 `}>
      <div className="flex mt-4 justify-between gap-[40px] relative">
        <ShopAside />
        <div className=" w-full">
        <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ShopLayout;
