import React, { useState } from "react";
import { Collapse } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllCategoriesQuery } from "../../redux/Service/api";

const ShopAside = () => {
  const [activeKeys, setActiveKeys] = useState<Record<string, string[]>>({});
  console.log("activeKeys", activeKeys);
  
  const { data: allCategoriesData } = useGetAllCategoriesQuery(undefined);
  // Function to handle collapse state change
  const handleCollapseChange = (key: string, parentId?: string) => {
    setActiveKeys((prev) => {
      const newActiveKeys = { ...prev };

      if (parentId) {
        // Ensure activeKeys[parentId] is an array
        if (!Array.isArray(newActiveKeys[parentId])) {
          newActiveKeys[parentId] = []; // Initialize as an empty array
        }

        // Add key to the array if not already present
        if (!newActiveKeys[parentId].includes(key)) {
          newActiveKeys[parentId].push(key);
        } else {
          // Remove key if it exists
          newActiveKeys[parentId] = newActiveKeys[parentId].filter((id) => id !== key);
        }
      }

      return newActiveKeys;
    });
  };

  // Categories mapping function with collapse
  function categoriesMapper(categories: any) {
    return categories?.map((category: any) => ({
      key: String(category?.id),
      label: category?.categories?.length > 0 ? (
        // If the category has children (subcategories), use collapse
        <div
          className="flex items-center justify-between"
          onClick={() => handleCollapseChange(category?.id, String(category?.id))}
        >
          <span className={`text-[16px] font-bold`}>{category?.name}</span>
          {activeKeys[String(category?.id)] ? (
            <MinusOutlined className="ml-2" />
          ) : (
            <PlusOutlined className="ml-2" />
          )}
        </div>
      ) : (
        // If the category has no children, display as a link
        <Link
          to={`/shop/catogery/Details/${category?.id}`} // Link to category details
          className="flex items-center justify-between text-[#000000] hover:underline"
        >
          <span>{category?.name}</span>
        </Link>
      ),
      ...(category?.categories?.length > 0 ? {
        children: (
          <Collapse
          accordion={false}
          activeKey={Object.values(activeKeys).flat()} // Merge all active keys
          onChange={(key) => handleCollapseChange(key as unknown as string)}
          expandIcon={() => null}
          items={categoriesMapper(category.categories)} // Recursively map subcategories
          className="bg-transparent border-0"
        />
        ),
      } : {
        // If no further children, don't add any collapsible element
        children: null, // No children to display
      }),
    }));
  }

  const allCategories = categoriesMapper(allCategoriesData?.data ?? []);

  return (
    <div className="ShopAside border-l-[1px] solid border-r-[1px] border-b-[1px] sticky w-[300px] overflow-auto h-[100vh] shopAside top-[0px] miillg:hidden">
      <Collapse
        accordion={false}
        activeKey={Object.values(activeKeys).flat()} // Merge all active keys
        onChange={(key) => handleCollapseChange(key as unknown as string)}
        expandIcon={() => null}
        items={allCategories}
        className="bg-transparent border-0"
      />
    </div>
  );
};

export default ShopAside;
