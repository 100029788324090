import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
// my style file
import "./treadMarkslider.scss";
// import required modules
import { Autoplay } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { useGetAllBrandsQuery } from "../../../redux/Service/api";

type IProps = {
  Duration: number;
};

const TreadMarkSlider = ({ Duration }: IProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const isMobile600 = useMediaQuery({ query: "(max-width: 600px)" });

  const { data: allBrandsData } = useGetAllBrandsQuery(undefined);
  const allBrands = allBrandsData?.data ?? [];
  const midIndex = Math.ceil(allBrands?.length / 2);
  const firstHalf = allBrands?.slice(0, midIndex) ?? [];
  const secondHalf = allBrands?.slice(midIndex) ?? [];
  return (
    <>
      <div className={`pt-5 pb-5 relative treadmark`}>
        <Swiper
          modules={[ Autoplay ]}
          autoplay={{
            delay: Duration, // Delay between slides in milliseconds
            disableOnInteraction: false, // Keep autoplay running after user interactions
            pauseOnMouseEnter: false, // Prevent pausing on hover
          }}
          spaceBetween={30}
          slidesPerView={isMobile ? (isMobile600 ? 2 : 3) : 6}
          className="mySwiper !h-full !unset !pr-5 !pl-5"
        >
          {firstHalf?.map((brand: any) => (
            <SwiperSlide key={brand?.id}>
              <img
                src={brand?.photo}
                className={`w-full h-full object-fill`}
                alt={brand?.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={`pt-5 pb-5 relative treadmark`}>
        <Swiper
          modules={[ Autoplay ]}
          autoplay={{
            delay: Duration, // Delay between slides in milliseconds
            disableOnInteraction: false, // Keep autoplay running after user interactions
            pauseOnMouseEnter: false, // Prevent pausing on hover
            reverseDirection: true, // Reverse the direction for the second swiper
          }}
          spaceBetween={30}
          slidesPerView={isMobile ? (isMobile600 ? 2 : 3) : 6}
          className="mySwiper !h-full !unset !pr-5 !pl-5"
        >
          {secondHalf?.map((brand: any) => (
            <SwiperSlide key={brand?.id}>
              <img
                src={brand?.photo}
                className={`w-full h-full object-fill`}
                alt={brand?.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default TreadMarkSlider;
