import { useParams } from "react-router";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import DeatailsContant from "./DeatailsContant";
import "./ShopCatogeryDetails.scss";
import { useGetOneProductQuery } from "../../redux/Service/api";
const ShopCatogeryDetails = () => {
  const { id } = useParams();
  const { data: productDetails } = useGetOneProductQuery(id);
  const product = productDetails?.data ?? null;
  return (
    <>
      <div className="flex flex-col w-full milgg:pr-[27%] milgg:pl-[27%] pl-[50px] pr-[50px] ShopDetails">
        <ShopLayoutTitle
          title={"Home"}
          PathTo={"/"}
          Page={"Shop"}
          subPage={"Agri"}
          pageDetails={"AgriDetails"}
        />
        <div className={`flex gap-[30px] justify-center miillg:flex-col miillg:mb-[80px]`}>
          <div className={`w-[40%] miillg:w-full rounded-[10px] h-[300px] mb-[50px]`}>
            <div className={`h-full rounded-[10px] border-[1px] solid border-[#ccc]`}>
              <img
                src={product?.photo}
                alt={product?.name}
                className={`rounded-[10px] w-full h-full object-cover object-center`}
              />
            </div>
          </div>
          <DeatailsContant product={product} />
        </div>
      </div>
    </>
  );
};
export default ShopCatogeryDetails;
