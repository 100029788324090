import { LoadingOutlined, ShoppingOutlined, UserOutlined } from "@ant-design/icons";
import UserDrawer from "../../UserDrawer";
import { Link } from "react-router-dom";
import { useGetCartProductsQuery } from "../../../redux/Service/api";
import { useEffect, useState } from "react";

type IProps = {
  isAuthenticated: boolean;
};
const EcommarceIcon = ({ isAuthenticated }: IProps) => {
  const { data: GetCartProducts, isLoading: LoadingCartProducts } = useGetCartProductsQuery({});
  const [prevLength, setPrevLength] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const currentLength = GetCartProducts?.data?.data?.length || 0;

  useEffect(() => {
    if (currentLength !== prevLength) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 500); // Match animation duration
      setPrevLength(currentLength);
    }
  }, [currentLength, prevLength]);
  return (
    <div className="pl-[40px] flex items-center gap-[20px]">
      {isAuthenticated === true ? (
        <div className="miillg:flex miillg:flex-col miillg:items-center">
          <Link to={"acountDetails"}>
            <UserOutlined className="text-[15px] border-[2px] border-[black] rounded-[50%] p-1 miillg:hidden" />
          </Link>
        </div>
      ) : (
        <UserDrawer />
      )}
      <div>
       {
        LoadingCartProducts ? 
        <LoadingOutlined className={`text-[27px] miillg:text-[35px]`} />
        :
        isAuthenticated == true &&
        <Link to={"/cart"}
          className={`relative`}
        >
          <div className={`${isAnimating ? "EcommarceIcon" : ""} absolute flex justify-center items-center top-[-10px] left-[15px] text-[#ffffff] bg-[#000000] w-[20px] h-[20px] rounded-[50%]`}>
            <span className={`font-bold text-[11px]`}>
              {currentLength}
            </span>
          </div>
          <ShoppingOutlined className={`text-[27px] miillg:text-[35px]`} />
        </Link>
       }
      </div>
    </div>
  );
};

export default EcommarceIcon;
