import CheckoutOrder from "./CheckoutOrder";
import Payment from "./Payment/Payment";
import "./checkout.scss";
const Checkout = () => {
  return (
    <div>
      <div className="flex gap-6 miillg:flex-col mt-5">
        <div className="w-[70%] miillg:w-full">
          <Payment />
        </div>
        <div className="w-[30%] miillg:w-full">
          <CheckoutOrder />
        </div>
      </div>
    </div>
  );
};
export default Checkout;
