import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import cursorImage from "../../../assets/swiper/drag.png";
import { useGetBannersQuery } from "../../../redux/Service/api";

const ElementorSlider = () => {
  const {
    data: allBannersData,
  } = useGetBannersQuery(undefined);

  const allBanners = allBannersData?.data ?? [];
  console.log("allBanners", allBanners);
  
  const cursorUrl = `url(${cursorImage}), auto`;
  return (
    <div
      className={`w-[70%] miilg:w-full miilg:mb-11 maxlm:mb-6  h-[475px] xxllg:h-[400px] maxlm:h-[350px] elementor`}
      style={{ cursor: cursorUrl }}
    >
      <Swiper
        pagination={true}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="h-full mySwiper"
        spaceBetween={22}
        slidesPerView={1}
        autoplay={{
          delay: 2300, // Delay between slides in milliseconds (3 seconds)
          disableOnInteraction: false, // Keep autoplay running after user interactions like swiping
        }}
      >
        {allBanners?.map((banner) => (
          <SwiperSlide key={banner?.id} className={`!relative`}>
            <div
              className={`absolute top-[50%] translate-y-[-50%] left-[40px]`}
            >
              <h2
                className={`text-[#00837E] text-[35px] xxllg:text-[28px] font-bold maxlm:mb-0 mb-2 maxlm:text-[25px]`}
              >
                New Age
              </h2>
              <h2
                className={`text-[#00837E] text-[35px] xxllg:text-[28px] font-bold maxlm:mb-1 mb-2 maxlm:text-[25px]`}
              >
                Motor Starter
              </h2>
              <h2
                className={`text-[#5E5E5E] text-[30px] xxllg:text-[22px] maxlm:mb-3 mb-5 maxlm:text-[19px]`}
              >
                From Shneider Electric
              </h2>
              <p
                className={`pr-4 pl-4 pt-3 pb-3 bg-[#000] w-fit text-[#ffff] rounded-[3px] uppercase`}
              >
                shop now
              </p>
            </div>
            <img src={banner.photo} className="w-full h-full" alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ElementorSlider;
